<template>
  <div class="flex-1">
    <v-select
      id="category"
      v-model="currentlySelectedCategories"
      name="category"
      class="font-medium rounded text-gray-700 w-full text-sm bg-white -mt-2"
      label="category"
      :multiple="true"
      :reduce="category => category.id"
      :options="categoryOptions"
      :clearable="true"
      :loading="false"
      :deselect-from-dropdown="true"
      :selectable="isSelectable"
      placeholder="Select categories"
      @input="update"
    />
  </div>
</template>

<script>
import { SupportApi } from '@/api';

export default {
  props: {
    preSelectedCategories: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      form: {
        categories: '',
      },
      loading: false,
      categoryOptions: [],
      currentlySelectedCategories: [],
    };
  },
  mounted() {
    this.setInitialFormValues();
  },
  methods: {
    isSelectable(category) {
      const categoryFromSelected = this.currentlySelectedCategories.find(
        selectedCategory => category.id === selectedCategory
      );

      // The given category was not found amoung the currently selected categories
      return categoryFromSelected === undefined;
    },
    update() {
      this.$emit('update', this.currentlySelectedCategories);
    },
    async setInitialFormValues() {
      const { data } = await SupportApi.getSupportTicketCategories();
      this.currentlySelectedCategories = this.preSelectedCategories.map(
        // This ternary operator is required as the reduce function on the v-select
        // does not appear to be run until something is actually selected. Therefore,
        // the data we get here will be either in the form of a category object { category, id, label }
        // or the reduced version of that, which is just an Integer representing the id.
        categoryData => (categoryData.id ? categoryData.id : categoryData)
      );

      /* --- Fill out the select options --- */
      this.categoryOptions = data.support_ticket_categories;
    },
  },
};
</script>

<style lang="scss">
.vs__dropdown-toggle {
  min-height: 34px;
}
</style>
