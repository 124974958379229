<template>
  <div>
    <div
      class="flex flex-col space-y-2 overflow-hidden border-b pb-2"
      style="max-height: 36vh"
    >
      <div v-if="ticket.answers && ticket.answers.length" class="overflow-auto">
        <div
          v-for="(answer, index) in sortedAnswers"
          :key="index"
          class="mt-4 rounded bg-gray-100 p-2"
        >
          <p class="mb-4">
            {{ answer.message }}
          </p>
          <div class="text-right text-sm text-gray-700">
            <p class="">
              {{
                answer.user && answer.user.display_name
                  ? answer.user.display_name
                  : ''
              }}
            </p>
            <p class="">
              {{ format(new Date(answer.created_at), 'yyyy-MM-dd HH:mm') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-2">
      <ValidationObserver ref="observer">
        <form @submit.prevent="validate">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|min:4"
            name="message"
          >
            <transition name="fade">
              <p
                v-if="errors.length && formValidated"
                class="py-2 font-semibold text-red-800"
              >
                {{ errors[0] }}
              </p>
            </transition>

            <textarea-input
              id="message"
              v-model="message"
              name="message"
              class="text-sm"
              :disabled="loading"
            />
          </ValidationProvider>
          <base-button color="secondary" class="mt-2 w-full" :loading="loading">
            Send
          </base-button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format } from 'date-fns';

export default {
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: '',
      loading: false,
      format,
      formValidated: false,
    };
  },
  computed: {
    ...mapGetters('user', { userId: 'getUserId' }),
    sortedAnswers() {
      const answers = this.ticket.answers.slice();
      return answers.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });
    },
  },
  mounted() {
    if (this.ticket.answers?.length) this.setRead();
  },
  methods: {
    ...mapActions('support', ['sendAnswer', 'updateAnswer', 'fetchTicket']),
    async validate() {
      this.formValidated = true;
      const valid = await this.$refs.observer.validate();
      if (valid) {
        this.sendReply();
      }
    },
    async sendReply() {
      try {
        this.loading = true;
        const { data } = await this.sendAnswer({
          id: this.ticket.id,
          data: { message: this.message },
        }).catch(e => {
          throw e;
        });

        this.$notify({
          group: 'primary',
          title: 'Success!',
          text: 'Reply sent',
        });

        this.message = '';
        this.formValidated = false;
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });

        return data;
      } catch (error) {
        this.loading = false;
        throw error;
      }
    },
    setRead() {
      const promises = [];
      this.ticket.answers.forEach(answer => {
        if (!answer.read && answer.user_id !== this.userId) {
          promises.push(
            this.updateAnswer({
              answerId: answer.id,
              ticketId: answer.support_id,
              data: { message: answer.message, read: 1 },
            }).then(res => {
              this.update(res.data.support_message);
            })
          );
        }
      });
      Promise.all(promises).catch(e => {
        console.log({ e });
      });
    },
    update(updated) {
      const answerIndex = this.ticket.answers.findIndex(
        answer => answer.id === updated.id
      );
      this.ticket.answers[answerIndex].read = updated.read;
    },
  },
};
</script>
