<template>
  <div>
    <ValidationObserver
      ref="observer"
      tag="form"
      class="text-sm flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2 justify-between"
    >
      <ValidationProvider
        v-slot="{ errors }"
        rules="required|min_value:1|max_value:3"
        name="type"
        tag="div"
        class="flex-1"
      >
        <custom-select
          id="type"
          label-size="sm"
          size="sm"
          label-attr="name"
          value-attr="id"
          name="type"
          :options="typeOptions"
          placeholder="Select a type"
          :value="form.type"
          @change="form.type = $event, validate()"
        />
        <transition name="fade">
          <span
            v-if="errors.length"
            class="text-red-800 mt-2 font-semibold text-center"
          >
            The type field is required
          </span>
        </transition>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        rules="required|min_value:1|max_value:3"
        name="priority"
        tag="div"
        class="flex-1"
      >
        <custom-select
          id="priority"
          label-size="sm"
          size="sm"
          label-attr="shortName"
          value-attr="id"
          name="priority"
          :options="priorityOptions"
          placeholder="Select a priority"
          :value="form.priority"
          @change="form.priority = $event, validate()"
        />
        <transition name="fade">
          <span
            v-if="errors.length"
            class="text-red-800 mt-2 font-semibold text-center"
          >
            The priority field is required
          </span>
        </transition>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        rules="required|min_value:0|max_value:1"
        name="priority"
        tag="div"
        class="flex-1"
      >
        <custom-select
          id="status"
          label-size="sm"
          size="sm"
          label-attribute="name"
          value-attribute="id"
          name="status"
          :options="statusOptions"
          placeholder="Choose a status"
          :value="form.status"
          @change="form.status = $event, validate()"
        />
        <transition name="fade">
          <span
            v-if="errors.length"
            class="text-red-800 mt-2 font-semibold text-center"
          >
            The status field is required
          </span>
        </transition>
      </ValidationProvider>
    </ValidationObserver>

    <div class="mt-4">
      <support-ticket-category-select
        :pre-selected-categories="ticket.categories"
        @update="form.categories = $event, validate()"
      />
    </div>
  </div>
</template>


<script>
import { mapActions, mapState } from 'vuex';
import SupportTicketCategorySelect from './SupportTicketCategorySelect.vue';

export default {
  components: { SupportTicketCategorySelect },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        type: '',
        status: '',
        priority: '',
        categories: [],
      },
      loading: false,
    };
  },
  computed: {
    ...mapState('support', ['types', 'statuses', 'priorityList']),
    typeOptions() {
      return this.types.filter(type => type.id);
    },
    priorityOptions() {
      return this.priorityList.filter(prio => prio.id);
    },
    statusOptions() {
      return this.statuses.filter(status => status.id === 1 || status.id === 0);
    },
  },
  mounted() {
    this.setInitialFormValues();
  },
  methods: {
    ...mapActions('support', ['updateSupportTicket']),
    async validate() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        this.update();
      }
    },
    async update() {
      this.loading = true;
      this.$emit('loading', true);

      await this.updateSupportTicket({
        id: this.ticket.id,
        data: {
          ...this.form,
          message: this.ticket.message,
        },
      }).catch(e => {
        this.loading = false;
        this.$notify({
          group: 'error',
          title: 'Something went wrong',
          text: e.message,
        });
      });

      this.$emit('loading', false);
      this.$emit('updated');
    },
    setInitialFormValues() {
      this.form.type = this.ticket.type;
      this.form.priority = this.ticket.priority;
      this.form.status = this.ticket.status;
      this.form.categories = this.ticket.categories.map(
        category => category.id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
>>> .vs__dropdown-toggle {
  min-height: 34px;
}
</style>
