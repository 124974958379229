var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ValidationObserver",
        {
          ref: "observer",
          staticClass:
            "text-sm flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2 justify-between",
          attrs: { tag: "form" },
        },
        [
          _c("ValidationProvider", {
            staticClass: "flex-1",
            attrs: {
              rules: "required|min_value:1|max_value:3",
              name: "type",
              tag: "div",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return [
                    _c("custom-select", {
                      attrs: {
                        id: "type",
                        "label-size": "sm",
                        size: "sm",
                        "label-attr": "name",
                        "value-attr": "id",
                        name: "type",
                        options: _vm.typeOptions,
                        placeholder: "Select a type",
                        value: _vm.form.type,
                      },
                      on: {
                        change: function ($event) {
                          ;(_vm.form.type = $event), _vm.validate()
                        },
                      },
                    }),
                    _c("transition", { attrs: { name: "fade" } }, [
                      errors.length
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "text-red-800 mt-2 font-semibold text-center",
                            },
                            [_vm._v(" The type field is required ")]
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("ValidationProvider", {
            staticClass: "flex-1",
            attrs: {
              rules: "required|min_value:1|max_value:3",
              name: "priority",
              tag: "div",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return [
                    _c("custom-select", {
                      attrs: {
                        id: "priority",
                        "label-size": "sm",
                        size: "sm",
                        "label-attr": "shortName",
                        "value-attr": "id",
                        name: "priority",
                        options: _vm.priorityOptions,
                        placeholder: "Select a priority",
                        value: _vm.form.priority,
                      },
                      on: {
                        change: function ($event) {
                          ;(_vm.form.priority = $event), _vm.validate()
                        },
                      },
                    }),
                    _c("transition", { attrs: { name: "fade" } }, [
                      errors.length
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "text-red-800 mt-2 font-semibold text-center",
                            },
                            [_vm._v(" The priority field is required ")]
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("ValidationProvider", {
            staticClass: "flex-1",
            attrs: {
              rules: "required|min_value:0|max_value:1",
              name: "priority",
              tag: "div",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return [
                    _c("custom-select", {
                      attrs: {
                        id: "status",
                        "label-size": "sm",
                        size: "sm",
                        "label-attribute": "name",
                        "value-attribute": "id",
                        name: "status",
                        options: _vm.statusOptions,
                        placeholder: "Choose a status",
                        value: _vm.form.status,
                      },
                      on: {
                        change: function ($event) {
                          ;(_vm.form.status = $event), _vm.validate()
                        },
                      },
                    }),
                    _c("transition", { attrs: { name: "fade" } }, [
                      errors.length
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "text-red-800 mt-2 font-semibold text-center",
                            },
                            [_vm._v(" The status field is required ")]
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("support-ticket-category-select", {
            attrs: { "pre-selected-categories": _vm.ticket.categories },
            on: {
              update: function ($event) {
                ;(_vm.form.categories = $event), _vm.validate()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }