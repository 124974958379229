var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-1" },
    [
      _c("v-select", {
        staticClass:
          "font-medium rounded text-gray-700 w-full text-sm bg-white -mt-2",
        attrs: {
          id: "category",
          name: "category",
          label: "category",
          multiple: true,
          reduce: function (category) {
            return category.id
          },
          options: _vm.categoryOptions,
          clearable: true,
          loading: false,
          "deselect-from-dropdown": true,
          selectable: _vm.isSelectable,
          placeholder: "Select categories",
        },
        on: { input: _vm.update },
        model: {
          value: _vm.currentlySelectedCategories,
          callback: function ($$v) {
            _vm.currentlySelectedCategories = $$v
          },
          expression: "currentlySelectedCategories",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }