var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "flex flex-col space-y-2 overflow-hidden border-b pb-2",
        staticStyle: { "max-height": "36vh" },
      },
      [
        _vm.ticket.answers && _vm.ticket.answers.length
          ? _c(
              "div",
              { staticClass: "overflow-auto" },
              _vm._l(_vm.sortedAnswers, function (answer, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "mt-4 rounded bg-gray-100 p-2" },
                  [
                    _c("p", { staticClass: "mb-4" }, [
                      _vm._v(" " + _vm._s(answer.message) + " "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "text-right text-sm text-gray-700" },
                      [
                        _c("p", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                answer.user && answer.user.display_name
                                  ? answer.user.display_name
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.format(
                                  new Date(answer.created_at),
                                  "yyyy-MM-dd HH:mm"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      { staticClass: "pt-2" },
      [
        _c("ValidationObserver", { ref: "observer" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.validate.apply(null, arguments)
                },
              },
            },
            [
              _c("ValidationProvider", {
                attrs: { rules: "required|min:4", name: "message" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var errors = ref.errors
                      return [
                        _c("transition", { attrs: { name: "fade" } }, [
                          errors.length && _vm.formValidated
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "py-2 font-semibold text-red-800",
                                },
                                [_vm._v(" " + _vm._s(errors[0]) + " ")]
                              )
                            : _vm._e(),
                        ]),
                        _c("textarea-input", {
                          staticClass: "text-sm",
                          attrs: {
                            id: "message",
                            name: "message",
                            disabled: _vm.loading,
                          },
                          model: {
                            value: _vm.message,
                            callback: function ($$v) {
                              _vm.message = $$v
                            },
                            expression: "message",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "base-button",
                {
                  staticClass: "mt-2 w-full",
                  attrs: { color: "secondary", loading: _vm.loading },
                },
                [_vm._v(" Send ")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }